import {Component, Input, OnInit} from '@angular/core';
import MapLegend from '../../../models/MapLegend';

@Component({
	selector: 'app-map-legend',
	templateUrl: './map-legend.component.html',
	styleUrls: ['./map-legend.component.scss']
})
export class MapLegendComponent implements OnInit {
	@Input()
	legends: MapLegend[] = [];

	constructor() {}

	ngOnInit() {}
}
