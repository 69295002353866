import {EventEmitter, Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import LoginRequest from '../../../models/LoginRequest';
import LoginResponse from '../../../models/LoginResponse';
import Shipment from '../../../models/Shipment';
import jwtDecode from 'jwt-decode';
import * as moment from 'moment';

const loginResponseLocalStorageKey = 'login-response';

@Injectable({
	providedIn: 'root'
})
export class RepositoryService {
	private loginEmitter = new EventEmitter<LoginResponse | null>();
	private loginValue: LoginResponse | null = null;

	constructor(private apiService: ApiService) {
		this.login = RepositoryService.load(loginResponseLocalStorageKey);
	}

	get login(): LoginResponse | null {
		return this.loginValue;
	}

	private set login(value: LoginResponse | null) {
		this.loginValue = value;
		RepositoryService.save(loginResponseLocalStorageKey, value);
		this.loginEmitter.emit(value);
	}

	private static save(key: string, data: any) {
		localStorage.setItem(key, JSON.stringify(data));
	}

	private static load<T>(key: string): T | null {
		const json = localStorage.getItem(key);
		if (!json) {
			return null;
		}
		return JSON.parse(json);
	}

	private static sanitizeShipment(shipment: Shipment): Shipment {
		return {
			...shipment,
			actions: shipment.actions.map(action => ({
				...action,
				timestamp: moment(action.timestamp)
			})),
			history: shipment.history.map(history => ({
				...history,
				timestamp: moment(history.timestamp)
			})),
			pickup: moment(shipment.pickup),
			delivery: moment(shipment.delivery)
		};
	}

	async loginUser(loginRequest: LoginRequest) {
		try {
			const login = (await this.apiService.login(loginRequest)).data;
			login.jwtPayload = jwtDecode(login.jwt);
			this.login = login;
		} catch (e) {
			this.login = null;
		}
	}

	async getShipments(): Promise<Shipment[]> {
		const token = this.login?.jwt;
		if (!token) {
			throw new Error('Token not present. Please login.');
		}
		return (await this.apiService.getShipments(token)).data.map(shipment =>
			RepositoryService.sanitizeShipment(shipment)
		);
	}

	async getShipment(id: number): Promise<Shipment> {
		return RepositoryService.sanitizeShipment(
			(await this.apiService.getShipment(id)).data
		);
	}
}
