import {Component, OnInit} from '@angular/core';
import Shipment from '../../../models/Shipment';
import {RepositoryService} from '../../services/repository/repository.service';
import {Router} from '@angular/router';

@Component({
	selector: 'app-shipments',
	templateUrl: './shipments.page.html',
	styleUrls: ['./shipments.page.scss']
})
export class ShipmentsPage implements OnInit {
	static url = 'shipments';

	shipments: Shipment[] = [];
	displayedColumns: string[] = [
		'pickup',
		'delivery',
		'currentPoint',
		'customerLoad',
		'ourSideLoad',
		'type',
		'origin',
		'destination'
	];

	constructor(private repository: RepositoryService) {}

	static async navigate(router: Router) {
		await router.navigateByUrl(ShipmentsPage.url);
	}

	ngOnInit(): void {
		this.loadShipments();
	}

	loadShipments() {
		this.repository
			.getShipments()
			.then(res => (this.shipments = res))
			.catch(err => console.error(err));
	}
}
