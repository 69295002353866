import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SearchPage} from './pages/search/search.page';
import {AuthGuard} from './guard/auth.guard';
import {ShipmentsPage} from './pages/shipments/shipments.page';
import {LoginGuard} from './guard/login.guard';
import {TrackingPage} from './pages/tracking/tracking.page';

const routes: Routes = [
	{
		path: SearchPage.url,
		component: SearchPage,
		canActivate: [LoginGuard]
	},
	{
		path: '',
		redirectTo: SearchPage.url,
		pathMatch: 'full'
	},
	{
		path: ShipmentsPage.url,
		component: ShipmentsPage,
		canActivate: [AuthGuard]
	},
	{
		path: TrackingPage.url,
		component: TrackingPage
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule {}
