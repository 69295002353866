import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import Shipment from '../../../models/Shipment';
import {RepositoryService} from '../../services/repository/repository.service';
import ShipmentHistory from '../../../models/ShipmentHistory';
import {AxiosError} from 'axios';
import {SearchPage} from '../search/search.page';

@Component({
	selector: 'app-tracking',
	templateUrl: './tracking.page.html',
	styleUrls: ['./tracking.page.scss']
})
export class TrackingPage implements OnInit {
	static urlRoot = 'tracking';
	static shipmentIdParam = 'shipmentId';
	static url = `${TrackingPage.urlRoot}/:${TrackingPage.shipmentIdParam}`;

	shipment: Shipment;
	displayedColumns: string[] = [
		'pickup',
		'delivery',
		'customerLoad',
		'ourSideLoad',
		'type',
		'origin',
		'destination'
	];
	history: ShipmentHistory[] = [];
	displayedHistoryColumns: string[] = ['timestamp', 'status'];
	loading = false;
	error = null;
	shipmentNotFound = false;
	defaultError = false;

	private shipmentId: number;

	constructor(
		private activatedRoute: ActivatedRoute,
		private repository: RepositoryService,
		private router: Router
	) {}

	get hasError(): boolean {
		return this.shipmentNotFound || this.defaultError;
	}

	static navigate(router: Router, shipmentId: string) {
		router.navigateByUrl(`${TrackingPage.urlRoot}/${shipmentId}`);
	}

	ngOnInit() {
		this.activatedRoute.params.subscribe(params => {
			if (params[TrackingPage.shipmentIdParam]) {
				this.shipmentId = params[TrackingPage.shipmentIdParam];
				this.loadShipment(this.shipmentId);
			}
		});
	}

	reloadShipment() {
		this.loadShipment(this.shipmentId);
	}

	home() {
		SearchPage.navigate(this.router);
	}

	private async loadShipment(id: number) {
		this.shipmentNotFound = false;
		this.defaultError = false;
		try {
			this.shipment = await this.repository.getShipment(id);
			this.history = this.shipment.history;
			setTimeout(() => {
				this.loadShipment(id);
			}, 60_000);
		} catch (e) {
			if (e.isAxiosError) {
				const axiosError: AxiosError = e;
				switch (axiosError.response?.status) {
					case 404:
						this.shipmentNotFound = true;
						break;
					default:
						this.defaultError = true;
						break;
				}
			} else {
				this.defaultError = true;
			}
		}
	}
}
