import {Component, OnInit} from '@angular/core';
import {RepositoryService} from '../../services/repository/repository.service';
import {Router} from '@angular/router';
import {TrackingPage} from '../tracking/tracking.page';
import * as validator from 'validator';

@Component({
	selector: 'app-search-page',
	templateUrl: './search.page.html',
	styleUrls: ['./search.page.scss']
})
export class SearchPage implements OnInit {
	static url = 'search';

	shipmentId = '';
	loading = false;
	inputValid = false;

	constructor(private repository: RepositoryService, private router: Router) {}

	static async navigate(router: Router) {
		await router.navigateByUrl(SearchPage.url);
	}

	ngOnInit(): void {}

	click(): void {
		TrackingPage.navigate(this.router, this.shipmentId);
	}

	onInputChange(event) {
		this.shipmentId = event.detail.value;
		this.inputValid = validator.isUUID(this.shipmentId);
	}
}
