import {JwtPayload} from 'jwt-decode';

export const isJwtExpired = (jwtPayload?: JwtPayload): boolean => {
	const exp = jwtPayload?.exp ?? -1;
	const now = Math.ceil(Date.now() / 1000);
	return exp < now;
};

export const delay = (ms: number): Promise<void> =>
	new Promise(resolve => setTimeout(resolve, ms));
