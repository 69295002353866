import {Injectable} from '@angular/core';
import LoginRequest from 'src/models/LoginRequest';
import axios, {AxiosPromise} from 'axios';
import {environment} from '../../../environments/environment';
import LoginResponse from '../../../models/LoginResponse';
import Shipment from '../../../models/Shipment';

@Injectable({
	providedIn: 'root'
})
export class ApiService {
	private client = axios.create({
		baseURL: environment.apiUrl
	});

	constructor() {}

	login(login: LoginRequest): AxiosPromise<LoginResponse> {
		return this.client.post('/auth/local', login);
	}

	getShipments(token: string): AxiosPromise<Shipment[]> {
		return this.client.get('/shipments', {
			headers: {
				// eslint-disable-next-line @typescript-eslint/naming-convention
				Authorization: `Bearer ${token}`
			}
		});
	}

	getShipment(id: number): AxiosPromise<Shipment> {
		return this.client.get(`/shipments/${id}`);
	}
}
