import {Injectable} from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivate,
	Router,
	RouterStateSnapshot,
	UrlTree
} from '@angular/router';
import {Observable} from 'rxjs';
import {RepositoryService} from '../services/repository/repository.service';
import {isJwtExpired} from '../../utils/utils';
import {SearchPage} from '../pages/search/search.page';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate {
	constructor(private repository: RepositoryService, private router: Router) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	):
		| Observable<boolean | UrlTree>
		| Promise<boolean | UrlTree>
		| boolean
		| UrlTree {
		if (!isJwtExpired(this.repository.login?.jwtPayload)) {
			return true;
		}
		SearchPage.navigate(this.router);
		return false;
	}
}
