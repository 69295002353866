import {Component, Input, OnInit} from '@angular/core';
import Shipment from '../../../models/Shipment';
import {isMoment, Moment} from 'moment';

@Component({
	selector: 'app-shipments-table',
	templateUrl: './shipments-table.component.html',
	styleUrls: ['./shipments-table.component.scss']
})
export class ShipmentsTableComponent implements OnInit {
	@Input() shipments: Shipment[];
	@Input() displayedColumns: string[];
	@Input() hiddenColumnsSmall = []; // ['pickup', 'delivery'];

	constructor() {}

	ngOnInit() {}

	hiddenOnSmallScreens(field: string) {
		return this.hiddenColumnsSmall.indexOf(field) >= 0;
	}

	titleOf(field: string): string {
		switch (field) {
			case 'ourSideLoad':
				return 'Our Side Load#';
			case 'customerLoad':
				return 'Customer Load#';
			default:
				return field;
		}
	}

	stringify(rawData: any): string {
		if (!rawData) {
			return '';
		}
		if (Array.isArray(rawData)) {
			return rawData.map(item => this.stringify(item)).join(', ');
		}
		if (isMoment(rawData)) {
			return (rawData as Moment).format('DD/MM/yyyy HH:mm');
		}
		if (rawData.lat !== undefined && rawData.lng !== undefined) {
			return `lat: ${rawData.lat} | lng: ${rawData.lng}`;
		}
		if (typeof rawData === 'string') {
			return rawData;
		}
		return JSON.stringify(rawData);
	}
}
