import * as L from 'leaflet';
import 'leaflet-routing-machine';
import * as polyline from '@mapbox/polyline';
import * as corslite from '@mapbox/corslite';

export default class CustomRouter implements L.Routing.IRouter {
	options: any = {
		profile: 'driving',
		timeout: 30 * 1000,
		routingOptions: {
			alternatives: true,
			steps: true
		},
		polylinePrecision: 5,
		language: 'en'
	};
	hints;

	constructor(private osrmData: any) {}

	initialize(options) {
		L.Util.setOptions(this, options);
		this.hints = {
			locations: {}
		};
	}

	route(waypoints, callback, context, options) {
		options = L.extend({}, this.options.routingOptions, options);
		const actualWaypoints = this.toWaypoints(
			waypoints,
			this.osrmData.waypoints
		);
		const alts = this.osrmData.routes.map(route => ({
			...this.convertRoute(route),
			inputWaypoints: waypoints,
			waypoints: actualWaypoints,
			properties: {
				isSimplified:
					!options || !options.geometryOnly || options.simplifyGeometry
			}
		}));

		context = context || callback;

		return corslite(
			'',
			this.bind(() => callback.call(context, null, alts), this)
		);
	}

	requiresMoreDetail(route, zoom, bounds) {
		if (!route.properties.isSimplified) {
			return false;
		}

		const waypoints = route.inputWaypoints;
		let i;
		for (i = 0; i < waypoints.length; ++i) {
			if (!bounds.contains(waypoints[i].latLng)) {
				return true;
			}
		}

		return false;
	}

	convertRoute(responseRoute) {
		const result = {
			name: '',
			coordinates: [],
			instructions: [],
			summary: {
				totalDistance: responseRoute.distance,
				totalTime: responseRoute.duration
			},
			waypointIndices: []
		};
		const waypointIndices = [];
		let index = 0;
		const leg = responseRoute.legs[0];
		const hasSteps = leg.steps.length > 0;
		for (const step of leg.steps) {
			const geometry = this.decodePolyline(step.geometry);
			result.coordinates.push.apply(result.coordinates, geometry);
			if (step.maneuver.type === 'depart' || step.maneuver.type === 'arrive') {
				waypointIndices.push(index);
			}

			index += geometry.length;
		}

		if (!hasSteps) {
			result.coordinates = this.decodePolyline(responseRoute.geometry);
		} else {
			result.waypointIndices = waypointIndices;
		}

		return result;
	}

	decodePolyline(routeGeometry) {
		const cs = polyline.decode(routeGeometry, this.options.polylinePrecision);
		const result = new Array(cs.length);
		let i;
		for (i = cs.length - 1; i >= 0; i--) {
			result[i] = L.latLng(cs[i]);
		}

		return result;
	}

	toWaypoints(inputWaypoints, vias) {
		const wps = [];
		let i;
		let viaLoc;
		for (i = 0; i < vias.length; i++) {
			viaLoc = vias[i].location;
			wps.push(
				new L.Routing.Waypoint(
					L.latLng(viaLoc[1], viaLoc[0]),
					inputWaypoints[i].name,
					inputWaypoints[i].options
				)
			);
		}

		return wps;
	}

	locationKey(location) {
		return location.lat + ',' + location.lng;
	}

	bind(fn, obj) {
		const slice = Array.prototype.slice;

		if (fn.bind) {
			return fn.bind.apply(fn, slice.call(arguments, 1));
		}

		const args = slice.call(arguments, 2);

		return () =>
			fn.apply(
				obj,
				args.length ? args.concat(slice.call(arguments)) : arguments
			);
	}
}
