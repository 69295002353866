import {Component} from '@angular/core';
import {environment} from '../environments/environment';
import {SwUpdate} from '@angular/service-worker';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss']
})
export class AppComponent {
	constructor(private swUpdate: SwUpdate, private snackbarCtrl: MatSnackBar) {
		console.log(environment.version);
		swUpdate.available.subscribe(() => {
			this.snackbarCtrl
				.open('We are updating your experience...', null, {
					duration: 2000
				})
				.afterDismissed()
				.subscribe(() => {
					window.location.reload();
				});
		});
		if (swUpdate.isEnabled) {
			console.log('Checking for App update');
			swUpdate
				.checkForUpdate()
				.finally(() => console.log('Update checking finished'));
		} else {
			swUpdate.activateUpdate().finally(async () => {
				console.log('Updates activated result: ' + swUpdate.isEnabled);
				if (swUpdate.isEnabled) {
					console.log('Checking for App update');
					await swUpdate.checkForUpdate();
				} else {
					console.log('SwUpdate is disabled (no service worker)');
				}
			});
		}
	}
}
