const {version} = require('../../package.json');

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	version,
	apiUrl: 'https://dev-api.trackit.solutions',
	tilesUrl: 'https://dev-tiles.trackit.solutions',
	// apiUrl: 'http://localhost:1337/',
	mapBoxToken:
		'pk.eyJ1IjoiYXNjaG90bXlhbiIsImEiOiJja3c1YTh6bzIwZWkxMnZudmRiZW53MWNuIn0.jPYEo6q8pqtzPY_CNs1C2g',
	firebaseConfig: {
		apiKey: 'AIzaSyBIt6iuYjt9j1fAbukZJKVePNq1g2Mmag4',
		authDomain: 'trackit-solutions.firebaseapp.com',
		projectId: 'trackit-solutions',
		storageBucket: 'trackit-solutions.appspot.com',
		messagingSenderId: '124066052463',
		appId: '1:124066052463:web:14269679ac3d2abea5509a',
		measurementId: 'G-CH9CX2ZHNL'
	}
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
