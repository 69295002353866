import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import * as L from 'leaflet';
import {Map} from 'leaflet';
import Shipment from '../../../models/Shipment';
import {
	createMap,
	drawRouteToMap,
	loadTileLayer
} from '../../../utils/map-utils';
import {
	DESTINATION,
	ORIGIN,
	TRUCK_MOVING,
	TRUCK_PAUSE
} from '../../../models/MapLegend';
import 'leaflet-routing-machine';

@Component({
	selector: 'app-map',
	templateUrl: './map.component.html',
	styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, OnDestroy {
	legends = [TRUCK_MOVING, TRUCK_PAUSE, ORIGIN, DESTINATION];
	private map: Map;
	private tileLayer: L.Layer;
	private control: L.Routing.Control = null;
	private currentPosMarker: L.Marker = null;
	private _shipment: Shipment = null;

	constructor() {}

	get shipment(): Shipment {
		// eslint-disable-next-line no-underscore-dangle
		return this._shipment;
	}

	@Input()
	set shipment(value: Shipment) {
		const prevShipment = this.shipment;
		// eslint-disable-next-line no-underscore-dangle
		this._shipment = value;
		if (value) {
			this.initMap(value, prevShipment);
		}
	}

	ngOnInit() {
		console.log('ngOnInit');
		this.map = createMap('map');
		this.tileLayer = loadTileLayer(this.map);
	}

	ngOnDestroy() {
		console.log('ngOnDestroy');
		if (this.map) {
			console.log('remove map');
			if (this.tileLayer) {
				this.tileLayer.removeFrom(this.map);
				this.tileLayer = null;
			}
			this.map.remove();
			this.map = null;
		}
	}

	private initMap(shipment: Shipment, prevShipment: Shipment) {
		const {control, currentPosMarker} = drawRouteToMap(
			this.map,
			shipment,
			prevShipment,
			this.control,
			this.currentPosMarker
		);
		this.control = control;
		this.currentPosMarker = currentPosMarker;
	}
}
