import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {SearchPage} from './pages/search/search.page';
import {ShipmentsPage} from './pages/shipments/shipments.page';
import {FormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatTableModule} from '@angular/material/table';
import {TrackingPage} from './pages/tracking/tracking.page';
import {ShipmentsTableComponent} from './components/shipments-table/shipments-table.component';
import {HistoryTableComponent} from './components/history-table/history-table.component';
import {MapComponent} from './components/map/map.component';
import {MapLegendComponent} from './components/map-legend/map-legend.component';
import {MapLegendItemComponent} from './components/map-legend-item/map-legend-item.component';
import {ServiceWorkerModule} from '@angular/service-worker';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {environment} from '../environments/environment';
import {AngularFireAnalyticsModule} from '@angular/fire/compat/analytics';
import {AngularFireModule} from '@angular/fire/compat';

@NgModule({
	declarations: [
		AppComponent,
		SearchPage,
		ShipmentsPage,
		TrackingPage,
		ShipmentsTableComponent,
		HistoryTableComponent,
		MapComponent,
		MapLegendComponent,
		MapLegendItemComponent
	],
	entryComponents: [],
	imports: [
		AngularFireModule.initializeApp(environment.firebaseConfig),
		AngularFireAnalyticsModule,
		BrowserModule,
		IonicModule.forRoot(),
		AppRoutingModule,
		FormsModule,
		BrowserAnimationsModule,
		MatTableModule,
		MatSnackBarModule,
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: environment.production
		})
	],
	providers: [{provide: IonicRouteStrategy, useClass: IonicRouteStrategy}],
	bootstrap: [AppComponent]
})
export class AppModule {}
