import * as L from 'leaflet';
import {latLngBounds} from 'leaflet';
import 'leaflet-routing-machine';
import CustomRouter from 'src/map/CustomRouter';
import {environment} from '../environments/environment';
import GeoPoint from '../models/GeoPoint';
import Shipment from '../models/Shipment';

export const createMap = (htmlId: string): L.Map =>
	L.map(htmlId, {
		// dragging: false,
		// trackResize: true,
		// zoomControl: false,
		// boxZoom: false,
		keyboard: false,
		doubleClickZoom: false,
		// touchZoom: false,
		// scrollWheelZoom: false,
		minZoom: 4,
		zoomSnap: 0.5
	});

export const loadTileLayer = (map: L.Map): L.Layer =>
	L.tileLayer(
		//`${environment.tilesUrl}/tile/{z}/{x}/{y}.png`,
		'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}',
		{
			id: 'mapbox/streets-v11',
			accessToken: environment.mapBoxToken
		}
	).addTo(map);

export const geoPointToLatLng = (point: GeoPoint): L.LatLng =>
	new L.LatLng(point.lat, point.lng);

export const addMarkerToMap = (
	map: L.Map,
	point: GeoPoint,
	flip: boolean = false,
	status: undefined | 'running' | 'pause' = undefined
) => {
	let iconName;
	switch (status) {
		case 'running':
			iconName = 'truck-moving';
			break;
		case 'pause':
			iconName = 'gas-pump';
			break;
	}
	let icon;
	if (iconName) {
		const html = document.createElement('i');
		html.className = `fas fa-${iconName}`;
		if (flip) {
			html.className += ' flip-y';
		}
		icon = new L.DivIcon({
			html
		});
	}
	return L.marker(geoPointToLatLng(point), {
		draggable: false,
		icon
	}).addTo(map);
};

export const fitMapToBounds = (map: L.Map, points: GeoPoint[]) => {
	const bounds = latLngBounds([points[0], points[1]]);
	map.setMaxBounds([
		[49.866, -128.584],
		[23.4, -61.35]
	]);
	map.fitBounds(bounds, {
		padding: [20, 20]
	});
};

export const removeControl = (map: L.Map, control: L.Routing.Control) => {
	map.removeControl(control);
};

export const removeMarker = (map: L.Map, marker: L.Marker) => {
	marker.removeFrom(map);
};

export const geoPointsEqual = (point1: GeoPoint, point2: GeoPoint): boolean =>
	point1?.lat === point2?.lat && point1?.lng === point2?.lng;

export const drawRouteToMap = (
	map: L.Map,
	shipment: Shipment,
	prevShipment: Shipment,
	prevControl: L.Routing.Control,
	prevMarker: L.Marker
): {
	control: L.Routing.Control;
	currentPosMarker: L.Marker;
} => {
	const redrawRoute =
		!prevControl ||
		!prevShipment ||
		(prevControl &&
			(!geoPointsEqual(shipment.startPoint, prevShipment?.startPoint) ||
				!geoPointsEqual(shipment.endPoint, prevShipment?.endPoint)));
	const redrawMarker =
		!prevMarker ||
		!prevShipment ||
		(prevMarker &&
			(!geoPointsEqual(shipment.currentPoint, prevShipment?.currentPoint) ||
				shipment.actions[shipment.actions.length - 1] !==
					prevShipment.actions[prevShipment.actions.length - 1]));

	if (!redrawMarker && !redrawRoute) {
		return {
			control: prevControl,
			currentPosMarker: prevMarker
		};
	}

	if (redrawRoute && prevControl) {
		removeControl(map, prevControl);
	}
	if (redrawMarker && prevMarker) {
		removeMarker(map, prevMarker);
	}
	const wayPoints = [
		geoPointToLatLng(shipment.startPoint),
		geoPointToLatLng(shipment.endPoint)
	];
	let control = prevControl;
	if (redrawRoute) {
		control = L.Routing.control({
			waypoints: wayPoints,
			router: new CustomRouter(shipment.osrmData),
			routeDragInterval: 0,
			routeWhileDragging: false,
			autoRoute: true,
			addWaypoints: false,
			lineOptions: {
				addWaypoints: false,
				extendToWaypoints: false,
				missingRouteTolerance: 10
			},
			plan: new L.Routing.Plan(wayPoints, {
				draggableWaypoints: false,
				createMarker: waypointIndex => {
					const html = document.createElement('i');
					html.className =
						'fas fa-' +
						(waypointIndex === 0 ? 'truck-loading first' : 'warehouse last');
					return new L.Marker(wayPoints[waypointIndex], {
						icon: new L.DivIcon({
							html
						})
					});
				}
			})
		}).addTo(map);
	}
	let currentPosMarker = prevMarker;
	if (redrawMarker) {
		const lastAction = shipment.actions[shipment.actions.length - 1];
		if (shipment.currentPoint && lastAction) {
			let status;
			switch (lastAction.key) {
				case 'START':
				case 'RESUME':
					status = 'running';
					break;
				case 'PAUSE':
				case 'END':
					status = 'pause';
					break;
			}
			currentPosMarker = addMarkerToMap(
				map,
				shipment.currentPoint,
				shipment.startPoint.lng > shipment.endPoint.lng,
				status
			);
		}
	}
	if (redrawRoute) {
		fitMapToBounds(map, [shipment.startPoint, shipment.endPoint]);
	}
	return {
		control,
		currentPosMarker
	};
};
