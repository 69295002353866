export default interface MapLegend {
	icon: string;
	description: string;
}

export const TRUCK_MOVING: MapLegend = {
	icon: 'truck-moving',
	description: 'Rolling'
};

export const TRUCK_PAUSE: MapLegend = {
	icon: 'gas-pump',
	description: 'Pause'
};

export const DESTINATION: MapLegend = {
	icon: 'warehouse',
	description: 'Destination'
};

export const ORIGIN: MapLegend = {
	icon: 'truck-loading',
	description: 'Origin'
};
