import {Component, Input, OnInit} from '@angular/core';
import {isMoment, Moment} from 'moment';
import ShipmentHistory from '../../../models/ShipmentHistory';

@Component({
	selector: 'app-history-table',
	templateUrl: './history-table.component.html',
	styleUrls: ['./history-table.component.scss']
})
export class HistoryTableComponent implements OnInit {
	@Input() history: ShipmentHistory[];
	@Input() displayedColumns: string[];

	constructor() {}

	ngOnInit() {}

	stringify(columnName: string, rawData: any): string {
		if (!rawData) {
			return '';
		}
		if (Array.isArray(rawData)) {
			return rawData.map(item => this.stringify(columnName, item)).join(', ');
		}
		if (isMoment(rawData)) {
			return (rawData as Moment).format('DD/MM/yyyy HH:mm');
		}
		if (rawData.lat !== undefined && rawData.lng !== undefined) {
			return `lat: ${rawData.lat} | lng: ${rawData.lng}`;
		}
		if (typeof rawData === 'string') {
			if (columnName === 'status') {
				return rawData.toUpperCase().replace('_', ' ');
			}
			return rawData;
		}
		return JSON.stringify(rawData);
	}
}
