import {Component, Input, OnInit} from '@angular/core';
import MapLegend from '../../../models/MapLegend';

@Component({
	selector: 'app-map-legend-item',
	templateUrl: './map-legend-item.component.html',
	styleUrls: ['./map-legend-item.component.scss']
})
export class MapLegendItemComponent implements OnInit {
	@Input()
	legend: MapLegend;

	constructor() {}

	ngOnInit() {}
}
